import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/Banner"
import Card from "../components/Card"
import ButtonLink from "../components/ButtonLink"

const InfosMeteoPage = function ({
  data: {
    infosMeteoJson,
    flashInfos,
    flashMeteo,
    flashsBonus,
    minuteSolidarite,
    minuteVillAgenda,
    minutePlusTardJeSerai,
    minuteEmploi,
  },
  location,
}) {
  return (
    <Layout>
      <Seo
        title={infosMeteoJson.title}
        description={infosMeteoJson.description}
        pathname={location.pathname}
      />
      <Banner
        background={infosMeteoJson.banner.background}
        content={infosMeteoJson.banner.content}
      />
      <div className="container">
        <section>
          {parse(infosMeteoJson.intro)}
          {parse(infosMeteoJson.infos)}
          <ul className="grid grid-cols-2 gap-10">
            <Card
              item={{
                ...flashInfos,
                link: ``,
              }}
            />
            <Card
              item={{
                ...flashMeteo,
                link: ``,
              }}
            />
          </ul>
          {parse(infosMeteoJson.bonusInfos)}
          <ul className="grid grid-cols-4 gap-5">
            {/*
            {flashsBonus.nodes.map(function (item) {
              return <Card key={uuidv4()} item={item} />
            })} */}
            <Card
              item={{
                ...minuteSolidarite.podcasts[2],
                link: `/ensemble-au-micro/podcasts`,
              }}
            />
            <Card
              item={{
                ...minuteVillAgenda.podcasts[9],
                link: `/ensemble-au-micro/podcasts`,
              }}
            />
            <Card
              item={{
                ...minutePlusTardJeSerai.podcasts[6],
                link: `/ensemble-au-micro/podcasts`,
              }}
            />
            <Card
              item={{
                ...minuteEmploi.podcasts[19],
                link: `/minute-emploi`,
              }}
            />
          </ul>
          {parse(infosMeteoJson.realiseFlashs.content)}
          <div className="text-center">
            <ButtonLink item={infosMeteoJson.realiseFlashs.button} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

InfosMeteoPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default InfosMeteoPage

export const query = graphql`
  query InfosMeteoPageQuery {
    infosMeteoJson {
      title
      description
      banner {
        background {
          publicURL
        }
        content
      }
      intro
      infos
      bonusInfos
      realiseFlashs {
        content
        button {
          icon
          label
          link
        }
      }
    }
    flashInfos: infosMeteoItemsJson(category: { eq: "infos" }) {
      title
      link
      banner {
        background {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    flashMeteo: infosMeteoItemsJson(category: { eq: "meteo" }) {
      title
      link
      banner {
        background {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    flashsBonus: allInfosMeteoItemsJson(filter: { category: { eq: "bonus" } }) {
      nodes {
        title
        link
        banner {
          background {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    minuteSolidarite: ensembleAuMicroPodcastsJson(
      podcasts: { elemMatch: { category: { eq: "solidarite" } } }
    ) {
      podcasts {
        category
        num
        date
        title
        voices
        file
      }
    }
    minuteVillAgenda: ensembleAuMicroPodcastsJson(
      podcasts: { elemMatch: { category: { eq: "vill-agenda" } } }
    ) {
      podcasts {
        category
        num
        date
        title
        voices
        file
      }
    }
    minutePlusTardJeSerai: ensembleAuMicroPodcastsJson(
      podcasts: { elemMatch: { category: { eq: "plus-tard-je-serai" } } }
    ) {
      podcasts {
        category
        num
        date
        title
        voices
        file
      }
    }
    minuteEmploi: ensembleAuMicroPodcastsJson(
      podcasts: { elemMatch: { category: { eq: "emploi" } } }
    ) {
      podcasts {
        category
        num
        date
        title
        voices
        file
      }
    }
  }
`
